import React, { useState } from "react";
import Modal from "../UI/Modal/Modal";
import FormatModal from "../UI/FormatModal/FormatModal"
function ContentModal({batchCode,showContentModal,contentModal,...props}) {
    let contentOption=[
        {
            text:"Videos",
            id:0
        },
        {
            text:"Study Material",
            id:1
        },{
            text:"Assignments",
            id:2
        },{
            text:"Daily Practice Papers",
            id:3
        }
    ]

    let [type,setType]=useState(0)

    const openBatchLink=()=>{
        const featureObject = {
          0: "Videos",
          1: "STUDY MATERIAL",
          2: "Assignments",
          3: "Tests",
        };
    
        console.log(
          `SCREEN_BATCH_DETAILS,${batchCode},${featureObject[type]}`
        );
    
        if (
          window &&
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.onDeeplinkExecuted
        ) {
          window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
            `SCREEN_BATCH_DETAILS,${batchCode},${featureObject[type]}`
          );
        }
    
        if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
          window.mobile.onDeeplinkExecuted(
            `SCREEN_BATCH_DETAILS,${batchCode},${featureObject[type]}`
          );
        }
    }

    return (<Modal toggle={() => showContentModal(false)} hidden={!contentModal}>
        <FormatModal title={"Select type of content"}>
            <div className="contentModal">
                <p className="contentModal-heading"> Which type of content you want to add?</p>
                <div className="contentModal-option">
                   {contentOption.map((dt,ind)=>{return(
                        <div className="contentModal-option-item" onClick={()=>setType(dt.id)} key={dt.id}>
                            {dt.text}
                            <input className="contentModal-radio" type='radio' name={dt.text} checked={type===dt.id?true:false}/>
                            {/* <span className="contentModal-checkmark"></span> */}
                        </div>
                   )})}
                </div>
                <div className="contentModal-footer">
                    <button className="contentModal-footer-btn" onClick={()=>{
                            console.log(type)
                            openBatchLink()
                            showContentModal(false)
                            }}>
                        Add Content
                    </button>
                </div>
            </div>
        </FormatModal>
    </Modal>
    );
}

export default ContentModal;
