import React, { Component } from 'react';
import search from "../images/searchIcon.svg";

class SearchBar extends Component {
    render() {
        return (
            <div className="SearchBar">
                <div className="SearchBar__Div">
                    <img className="SearchBar__Div--Img" src={search} alt="search" />
                    <input onChange={this.props.searchValueSet} className="SearchBar__Div--Input" placeholder="Search" />
                </div>
            </div>
        )
    }
}

export default SearchBar;