import React, { Component } from "react";
import BatchList from "./BatchList";
import Button from "./Button";
import SearchBar from "./SeachBar";
import services from "../services";
import InfiniteScroll from "react-infinite-scroll-component";
import noBatchesimage from "../images/nobatches.png"
import ContentModal from "./ContentModal";
class FeaturePage extends Component {
  constructor() {
    super();
    this.state = {
      batchDetailsResponse: [],
      batchDetailsMain: {},
      Length: 0,
      Offset: 0,
      Limit: 20,
      hasMore: false,
      searchKey: "",
      searchingTimeout: 0,
      currentOption: null,
      batchID: "",
      batchCode: "",
      featureName: "",
      contentModal:false,
      courseLink:''
    };
  }

  componentDidMount() {
    const { batchDetailsResponse} = this.state;
    const featureType = this.getQuery()["featureType"];
    console.log(parseInt(featureType))
    this.setState({ featureName: parseInt(featureType) },()=>{
      this.state.featureName==7
      ? this.courseDetailsApi("first")
      : this.batchDetailsApi("first")
     
    });
  }

  getQuery = () => {
    let b = window.location.search
      .slice(1)
      .split("&")
      .map((qStr) => qStr.split("="))
      .reduce((acc, inc) => {
        acc[inc[0]] = inc[1];
        return acc;
      }, {});

    return b;
  };



  courseDetailsApi = async (callFrom) => {
    let { Limit, Offset, batchDetailsResponse } = this.state;
    let url = `v2/courses?tabCategoryId=1&offset=${this.state.Offset}&limit=${this.state.Limit}`;
    if (this.state.searchKey !== "") {
      url = url + `&search=${this.state.searchKey}`;
    }
    const batchDetails = await services.getApiCall(url);
    this.setState(
      {
        Length: batchDetails.data.data.courses,
        Offset: Offset + Limit,
        batchDetailsResponse:
          Offset === 0
            ? batchDetails.data.data.courses
            : [...batchDetailsResponse, ...batchDetails.data.data.courses],
        hasMore: batchDetails.data.data.courses.length > 9 ? true : false,
        batchDetailsMain: batchDetails.data.data,
      },
      () => {
        if (callFrom == "first") {
          const { batchDetailsResponse } = this.state;
          this.setState({
            batchCode:
              batchDetailsResponse && batchDetailsResponse[0].batchCode,
            courseLink:
              batchDetailsResponse && batchDetailsResponse[0].shareableLink
          });
        }
      }
    );
  };


  batchDetailsApi = async (callFrom) => {
    let { Limit, Offset, batchDetailsResponse } = this.state;
    let url = `v2/batches/details?offset=${this.state.Offset}&limit=${this.state.Limit}&sortBy=createdAt`;
    if (this.state.searchKey !== "") {
      url = url + `&search=${this.state.searchKey}`;
    }
    const batchDetails = await services.getApiCall(url);
    this.setState(
      {
        Length: batchDetails.data.data.totalBatches,
        Offset: Offset + Limit,
        batchDetailsResponse:
          Offset === 0
            ? batchDetails.data.data.totalBatches
            : [...batchDetailsResponse, ...batchDetails.data.data.totalBatches],
        hasMore: batchDetails.data.data.totalBatches.length > 9 ? true : false,
        batchDetailsMain: batchDetails.data.data,
      },
      () => {
        if (callFrom == "first") {
          const { batchDetailsResponse } = this.state;
          this.setState({
            batchCode:
              batchDetailsResponse && batchDetailsResponse[0] && batchDetailsResponse[0].batchCode,
          });
        }
      }
    );
  };

  nextfunction = () => {
    if(this.state.featureName==7){
      this.courseDetailsApi();
    }
    else{
      this.batchDetailsApi()
    };
  };

  searchValueSet = (e) => {
    if (this.state.searchingTimeout) {
      clearTimeout(this.state.searchingTimeout);
    }
    this.setState({
      searchKey: e.target.value,
      Offset: 0,
      batchDetailsResponse: [],
      searchingTimeout: setTimeout(() => {
        if(this.state.featureName==7){
          this.courseDetailsApi();
        }
        else{
          this.batchDetailsApi()
        };
      }, 500),
    });
  };

  selectBatch = (id) => {
    const { batchDetailsResponse } = this.state;
    this.setState(
      {
        currentOption: id,
        batchCode: batchDetailsResponse && batchDetailsResponse[id].batchCode,
        courseLink:batchDetailsResponse && batchDetailsResponse[id].shareableLink
      },
      () => {
        console.log(this.state.currentOption, "##");
      }
    );
  };
  
  showContentModal=(flag)=>{
    this.setState({contentModal:flag})
  }

  handleOverview = ()=>{
    const { batchCode} = this.state;
    console.log(
      `SCREEN_BATCH_DETAILS,${batchCode}`,"++"
    );
    if (
      window &&
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.onDeeplinkExecuted
    ) {
      window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
        `SCREEN_BATCH_DETAILS,${batchCode}`
      );
    }

    if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
      window.mobile.onDeeplinkExecuted(
        `SCREEN_BATCH_DETAILS,${batchCode}`
      );
    }
  }

  handleShareCourse = ()=>{
    let {courseLink}=this.state
    console.log(`UTIL_WHATSAPP,${courseLink}`)
      if (
        window &&
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.onDeeplinkExecuted
      ) {
        window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
          `UTIL_WHATSAPP,${courseLink}`
        );
      }

      if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
        window.mobile.onDeeplinkExecuted(
          `UTIL_WHATSAPP,${courseLink}`
        );
      }
  }

  openLink = () => {
    const { batchCode, featureName, currentOption } = this.state;
    const featureObject = {
      0: "Batch List",
      1: "Live Classes",
      2: "Assignments",
      3: "Tests",
      4: "Videos",
      5: "Live Classes",
      6: "Tests",
      7: "Course List",
      8: "STUDENTS",
      9:"OVERVIEW",
      10:"STUDY MATERIAL"
    };

    console.log(
      `SCREEN_BATCH_DETAILS,${batchCode},${featureObject[featureName]}`
    );

    if (
      window &&
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.onDeeplinkExecuted
    ) {
      window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
        `SCREEN_BATCH_DETAILS,${batchCode},${featureObject[featureName]}`
      );
    }

    if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
      window.mobile.onDeeplinkExecuted(
        `SCREEN_BATCH_DETAILS,${batchCode},${featureObject[featureName]}`
      );
    }
  };

  render() {
    const {
      batchDetailsResponse,
      currentOption,
      featureName,
      batchDetailsMain,
      contentModal,batchCode
    } = this.state;

    const noBatchesImage = () => {
      if(batchDetailsResponse && batchDetailsResponse.length === 0) {
        return (
          <img className="FeaturePage__BatchList--NoBatchesImg" src={noBatchesimage} alt="noBatches"/>
        )
      }
    }
    const viewItems = batchDetailsResponse.map((data, index) => {
      return (
        <BatchList
          key={index}
          selectBatch={this.selectBatch}
          name={data.name}
          currentIndex={currentOption}
          id={index}
        />
      );
    });
    return (
      <div className="FeaturePage">
        <SearchBar searchValueSet={this.searchValueSet} />
        <div className="FeaturePage__Details">
          {featureName == 0 
            ? "Select batch in which you want to add content"
            : featureName == 1
            ? "Select a batch to start the live class"
            : featureName == 2
            ? "Select a batch to give new assignments"
            : featureName == 3
            ? "Select a batch to assign new tests"
            : featureName == 5 || featureName == 6 
            ? "Select batch in which you want to engage students"
            : featureName == 7 
            ? "Select Course which you want to share"
            : featureName == 8 
            ? "Select batch in which you want to add students"
            : featureName == 9 
            ? "Select a batch"
            : featureName == 10
            ? "Select a batch to add study material"
            : "Select a batch to add new videos"
            
            }
        </div>
        <div className="FeaturePage__Batches">
         {featureName ==7 ?`Courses(${batchDetailsMain && batchDetailsMain.totalCount})` :`Batches(${batchDetailsMain && batchDetailsMain.batchesCount})` } 
        </div>
        <div className="FeaturePage__BatchList">
          <InfiniteScroll
            dataLength={viewItems.length}
            next={() => {
              this.nextfunction();
            }}
            hasMore={this.state.hasMore}
            loader={
              <p style={{ textAlign: "center", color: "#bbb" }}>
                <b>Loading...</b>
              </p>
            }
          >
            {viewItems}
          </InfiniteScroll>
          {noBatchesImage()}
        </div>
        <Button
          showContentModal={this.showContentModal}
          featureName={featureName}
          openLink={this.openLink}
          currentOption={currentOption}
          handleShareCourse={this.handleShareCourse}
          handleOverview={this.handleOverview}
        />
        {contentModal ? <ContentModal batchCode={batchCode} openLink={this.openLink} showContentModal={this.showContentModal} contentModal={contentModal}/>:""}
      </div>
    );
  }
}

export default FeaturePage;
