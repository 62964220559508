import axios from "axios";
import AppConfig from "./AppConfig";
import { getQuery } from "./utils";

let token =
  "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MTY2Mjc2Miwib3JnSWQiOjE3MCwibmFtZSI6IlJvaGFuIiwiZW1haWwiOiJyb2hhbkBjbGFzc3BsdXMuY28iLCJtb2JpbGUiOiI5MTkwMjg5Mjg5ODkiLCJ0eXBlIjozLCJpYXQiOjE2MDc0Mjg3NDUsImV4cCI6MTYwODAzMzU0NX0.QwUUpF4b4l8-409VrZKd8qsbiG5AOrEi-HyoEm4wYMWidp1daumXcd5I-8sOTEsE";
if (process.env.NODE_ENV === "development") {
  token =
    "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MTY2Mjc2Miwib3JnSWQiOjE3MCwibmFtZSI6IlJvaGFuIiwiZW1haWwiOiJyb2hhbkBjbGFzc3BsdXMuY28iLCJtb2JpbGUiOiI5MTkwMjg5Mjg5ODkiLCJ0eXBlIjozLCJpYXQiOjE2MDc0Mjg3NDUsImV4cCI6MTYwODAzMzU0NX0.QwUUpF4b4l8-409VrZKd8qsbiG5AOrEi-HyoEm4wYMWidp1daumXcd5I-8sOTEsE";
} else {
  if (process.env.REACT_APP_BUILD_ENV === "staging") {
    token =
      "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MTY2Mjc2Miwib3JnSWQiOjE3MCwibmFtZSI6IlJvaGFuIiwiZW1haWwiOiJyb2hhbkBjbGFzc3BsdXMuY28iLCJtb2JpbGUiOiI5MTkwMjg5Mjg5ODkiLCJ0eXBlIjozLCJpYXQiOjE2MDc0Mjg3NDUsImV4cCI6MTYwODAzMzU0NX0.QwUUpF4b4l8-409VrZKd8qsbiG5AOrEi-HyoEm4wYMWidp1daumXcd5I-8sOTEsE";
  }
}

const promoHeaders = {
  'Content-Type': 'application/json',
  "Api-Version": 10,
  "x-access-token": getQuery()["token"] ? getQuery()["token"] : token,
};

const getApiCall = (path, params) => {
  return axios
    .get(`${AppConfig.BASE_URL}/${path}`, {
      headers: promoHeaders,
    })
    .then((response) => {
      return response;
    });
};

const postApiCall = (path, data) => {
  return axios
    .post(`${AppConfig.BASE_URL}/${path}`, data, {
      headers: promoHeaders,
    })
    .then((response) => {
      return response;
    });
};

const services = {
  getApiCall,
  postApiCall,
};

export default services;

//eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6NDUzNzMzLCJvcmdJZCI6MSwibmFtZSI6IkNMUCBNYXN0ZXIiLCJlbWFpbCI6ImRldmFuc2hAY2xhc3NwbHVzLmNvIiwibW9iaWxlIjoiOTE4MDc2NzY3MzE1IiwidHlwZSI6MywiaWF0IjoxNTk1NDAyMDg2LCJleHAiOjE1OTcxMzAwODZ9.VxDbFl6wUHsAPeej84_5gBp3sYWawzcJU5JOJ1stodKgQXk24s9fnhbyvovxW6Uh
