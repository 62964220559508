let BASE_URL = "https://api.classplusapp.com";
// let BASE_URL = "https://apip.classplusapp.com";

// if (process.env.NODE_ENV === "development") {
//   BASE_URL = "https://csm.classplus.co/staging";
// } else {
//   if (process.env.REACT_APP_BUILD_ENV === "staging") {
//     BASE_URL = "https://csm.classplus.co/staging";
//   } else if (process.env.REACT_APP_BUILD_ENV === "production") {
//     BASE_URL = "https://csm.classplus.co/staging";
//   }
// }

export default {
  BASE_URL,
};

//wl-api-1.staging.classplus.co
