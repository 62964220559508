import React, { Component } from 'react';

class BatchList extends Component {
    render() {
        const {name,id,currentIndex} = this.props;
        return (
            <div onClick={() => {this.props.selectBatch(id)}} className="BatchList">
                <div className="BatchList__Names">{name}</div>
                <input className="BatchList__Radio" type='radio' name="radio" checked={id === currentIndex ? true : false}/>
                <span className="BatchList__Checkmark"></span>
            </div>
        )
    }
}

export default BatchList;