import React, { Component } from "react";

class Button extends Component {
  render() {
    const { featureName, currentOption } = this.props;
    console.log(currentOption, "!!!!!!!");
    return (
      <div className="GoLiveButton">
        <button
          onClick={featureName==0 
                  ?()=>this.props.showContentModal(true) 
                  :featureName==7
                  ?()=>this.props.handleShareCourse()
                  :featureName==9
                  ?()=>this.props.handleOverview()
                  :this.props.openLink}
          className={
            currentOption == null
              ? "GoLiveButton__Btn GoLiveButton__Btn--disabled"
              : "GoLiveButton__Btn"
          }
          disabled={currentOption == null}
        >
          {featureName === 0 || featureName === 5 || featureName === 6 || featureName === 7 || featureName ===8 || featureName ===9 || featureName ===10
            ? "Next"
            : featureName === 1
            ? "Go Live"
            : featureName === 2
            ? "Give Assignments"
            : featureName === 3
            ? "Assign Tests"
            : "Add New Videos"}
        </button>
      </div>
    );
  }
}

export default Button;
